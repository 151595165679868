<template>
    <div>
        <component v-bind:is="currentComponent"></component>
    </div>
</template>

<script>

    export default {
        name: "ContentPage",
        components: {
            about: () => import('@/components/content/About.vue'),
            aboutval: () => import('@/components/content/AboutVal.vue'),
            credit_app: () => import('@/components/content/CreditApp.vue'),
            conforming: () => import('@/components/content/Conforming.vue'),
            customcurbs: () => import('@/components/content/CustomCurbs.vue'),
            ductcal: () => import('@/components/content/DuctCal.vue'),
            employment: () => import('@/components/content/Employment.vue'),
            engineering: () => import('@/components/content/Engineering.vue'),
            field_measure: () => import('@/components/content/FieldMeasure.vue'),
            guarantee: () => import('@/components/content/Guarantee.vue'),
            install_instructions: () => import('@/components/content/InstallInstructions.vue'),
            isolation: () => import('@/components/content/Isolation.vue'),
            locations: () => import('@/components/content/Locations.vue'),
            mobile: () => import('@/components/content/Mobile.vue'),
            privacypolicy: () => import('@/components/my/PrivacyPolicy'),
            products: () => import('@/components/content/Products.vue'),
            shipping: () => import('@/components/content/Shipping.vue'),
            termsandconditions: () => import('@/components/my/TermsAndConditions'),
            territory: () => import('@/components/content/Territory.vue'),
            tools: () => import('@/components/content/Tools.vue'),
            new_user_r: () => import('@/components/content/NewUserPage.vue'),
            directory: () => import('@/components/tools/Directory.vue')
        },
        computed: {
            currentComponent: function() {
                let path = this.$route.path;
                return path.substring(1);
            }
        },
    }
</script>

<style scoped>

</style>